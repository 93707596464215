import { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material'
import Content from './Content.js';

const CrumbStack = (props) => {
    console.log("CrumbStack - props: ", props);
    const [stackSegs, setStackSegs] = useState(props.CrumbStackSegs);
    const breadcrumbs = [];
    stackSegs.forEach((segCur, segIndex) => {
        //console.log("CrumbStack - AAA");
        if ((segIndex+1) < stackSegs.length) {
            //console.log("CrumbStack - AAA 111");
            breadcrumbs.push(
                <Link 
                    underline="hover" 
                    key={segIndex+1}
                    id={segIndex}
                    color="inherit" 
                    href="/" 
                    onClick={(e) => {
                        e.preventDefault();
                        let stackSegsNew = [...stackSegs];
                        while (stackSegsNew.length > (parseInt(e.target.id)+1)) {
                            stackSegsNew.pop();
                        }
                        setStackSegs([...stackSegsNew]);
                    }}
                >
                    {segCur.Label}
                </Link>,
            );
        }
    });
    console.log("CrumbStack- stackSegs: ", stackSegs);
    breadcrumbs.push(
        <Typography 
            key={stackSegs.length}
            color="text.primary">
            {stackSegs[stackSegs.length-1].Label}
        </Typography>
    );
    return (
        <Box
            sx={{
                visibility: props.Visible === true ? 'visible' : 'hidden',
                display: props.Visible === true ? 'block' : 'none'
            }}
        >
            <Stack spacing={2}
                sx={{
                    paddingLeft: '1em'
                }}
            >
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="medium" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <IconButton
                sx={{
                    visibility: stackSegs.length > 1 ? 'visible' : 'hidden',
                    display: 'block',
                    borderRadius: '6px',
                    margin: '1em',
                    fontSize: 'medium'
                }}
                aria-label="back"
                onClick={e => {
                    e.stopPropagation();
                    let stackSegsNew = [...stackSegs];
                    stackSegsNew.pop();
                    setStackSegs([...stackSegsNew]);
                }}
            >
                <ArrowBackIcon fontSize="medium" />&nbsp;Go back
            </IconButton>
            <Box>
                {stackSegs.map((segCur, segIndex) =>
                    <Box
                        sx={{
                            visibility: segIndex === (stackSegs.length-1) ? 'visible' : 'hidden',
                            display: segIndex === (stackSegs.length-1) ? 'block' : 'none'
                        }}
                    >
                        <Content
                            DataAnchor={segCur}
                            Columns={segCur.Columns}
                            Row={segCur.Row != null ? segCur.Row : null}
                            ParentId={segCur.ParentId != null ? segCur.ParentId : null}
                            Shape={segCur.Shape}
                            CrumbStackSegs={segCur.CrumbStackSegs}
                            SetStackSegs={setStackSegs}
                            DrilldownMenuItem={segCur.DrilldownMenuItem}
                            List={segCur.List ?? ''}
                        >
                        </Content>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default CrumbStack;
