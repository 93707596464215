import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material'
import { Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const InitializeRight = () => {
  let retVal = [];
  let currentTime = GetCurrentTime();
  let secondLast = parseInt(GetMilliSecondsFromTime(currentTime)/1000);
  for (let i = 0; i < 3600; i++) {
    let secNumCur = secondLast - (3599 - i);
    let hourCur = parseInt(secNumCur/3600);
    let minuteCur = parseInt(parseInt(secNumCur%3600)/60);
    let secondCur = parseInt(parseInt(secNumCur%3600)%60);
    let secondStringCur = hourCur.toString().padStart(2, '0') + ':' + 
      minuteCur.toString().padStart(2, '0') + ':' + 
      secondCur.toString().padStart(2, '0');
    let secCur = {
      SecNum: secNumCur,
      Second: secondStringCur,
      Vol: null,
      PriceLast: null,
      AggVol: 0
    }
    retVal.push(secCur);
  }
  return retVal;
}

const ResyncRight = (right) => {
  let currentTime = GetCurrentTime();
  let secondLast = parseInt(GetMilliSecondsFromTime(currentTime)/1000);
  let secondsElapsed = secondLast - right[3599].SecNum;
  if (secondsElapsed > 0) {
    let i = 0;
    for (i = 0; i < (3600-secondsElapsed); i++ ) {
      right[i] = right[i+secondsElapsed];
    }
    let aggVol = 0;
    if (i > 0) {
      aggVol = right[i-1].AggVol;
    }
    for (; i < 3600; i++) {
      let secNumCur = secondLast - (3599 - i);
      let hourCur = parseInt(secNumCur/3600);
      let minuteCur = parseInt(parseInt(secNumCur%3600)/60);
      let secondCur = parseInt(parseInt(secNumCur%3600)%60);
      let secondStringCur = hourCur.toString().padStart(2, '0') + ':' + 
        minuteCur.toString().padStart(2, '0') + ':' + 
        secondCur.toString().padStart(2, '0');
      let secCur = {
        SecNum: secNumCur,
        Second: secondStringCur,
        Vol: null,
        PriceLast: null,
        AggVol: aggVol
      }
      right[i] = secCur;
     }
  }
}

const UpdateRight = (right, messageIn) => {
  let secArray = messageIn.SecArray ?? [];
  secArray.forEach(arrayItem => {
    if (arrayItem.PriceLast != null && arrayItem.PriceLast > 0.001 && arrayItem.Vol != null) {
      let secDateCur = new Date(parseInt(arrayItem.SecNum) * 1000);
      let dateTimeString = secDateCur.toLocaleString('en-GB', { timeZone: 'America/New_York' });
      let timeString = dateTimeString.substring(12, 20);
      let dataRightFound = right.find(cur => cur.Second === timeString);
      if (dataRightFound != null) {
        dataRightFound.PriceLast = arrayItem.PriceLast;
        dataRightFound.Vol = arrayItem.Vol;
      }
    }
  });
  let aggVol = right[0].AggVol;
  right.forEach((cur,curIndex) => {
    if (curIndex > 0) {
      if (cur.Vol != null) {
        aggVol += cur.Vol;
      }
      cur.AggVol = aggVol;
    }
  });
}

const GetCurrentTime = () => {
  let dateCur = new Date();
  let dateTimeString = dateCur.toLocaleString('en-GB', { timeZone: 'America/New_York' });
  return dateTimeString.substring(12, 20) + '.' + dateCur.getMilliseconds().toString().padStart(3, '0');
}

const GetLastUpdateTime = (messageIn) => {
  let dateCur = new Date(parseInt(messageIn.SecNum) * 1000 + parseInt(messageIn.MSCur));
  let dateTimeString = dateCur.toLocaleString('en-GB', { timeZone: 'America/New_York' });
  return dateTimeString.substring(12, 20) + '.' + dateCur.getMilliseconds().toString().padStart(3, '0');
}

const GetMilliSecondsFromTime = (time) => {
  let seconds = 
    ( ( (
          parseInt(time.substring(0, 2)) * 60
        ) + 
        parseInt(time.substring(3, 5))
      ) * 60 +
      parseInt(time.substring(6, 8))
    ) * 1000 + 
    parseInt(time.substring(9, 12));
  return seconds;
}

const GetDelay = (lastUpdateTime, currentTime) => {
  let current = GetMilliSecondsFromTime(currentTime);
  let last = GetMilliSecondsFromTime(lastUpdateTime);
  return (current-last).toString().padStart(3, '0');
}

const ContentItemElemGraph2 = (props) => {
    const [dataRight, setDataRight] = useState(InitializeRight);
    const [timer, setTimer] = useState(0);
    const [symbols, setSymbols] = useState([]);
    const [watchSymbol, setWatchSymbol] = useState('');
    const [removeSymbol, setRemoveSymbol] = useState('');
    const [addSymbol, setAddSymbol] = useState('');
    const [submitSymbolGo, setSubmitSymbolGo] = useState(false);
    const [restart, setRestart] = useState(false);
    const [currentTime, setCurrentTime] = useState('00:00:00.000');
    const [lastUpdateTime, setLastUpdateTime] = useState('00:00:00.000');
    const [delay, setDelay] = useState('0000');
    const handleWatchSymbol = (event) => {
      setWatchSymbol(event.target.value);
    };
    const handleRemoveSymbol = (event) => {
      setRemoveSymbol(event.target.value);
    };
    const handleAddSymbol = (event) => {
      setAddSymbol(event.target.value);
    };
    const handleSubmitSymbolGo = () => {
      setSubmitSymbolGo(true);
    };
    const handleRestart = () => {
      setRestart(true);
    };
  useEffect(() => {
    const updateData = (async () => {
      let criteria = '';
      if (submitSymbolGo) {
        criteria = 'SubmitSymbolGo';
        if (addSymbol > '') {
          setWatchSymbol(addSymbol);
          setRemoveSymbol('');
        }
        setSubmitSymbolGo(false);
      }
      if (restart) {
        criteria = 'Restart';
        setRestart(false);
      }
      let crudInfo = {
        Action: 'SetCriteria',
        Detail: {
          Criteria: criteria
        },
        GlobalFilter: ''
      };
      if (criteria === 'SubmitSymbolGo') {
        crudInfo.Detail.WatchSymbol = watchSymbol;
        crudInfo.Detail.RemoveSymbol = removeSymbol;
        crudInfo.Detail.AddSymbol = addSymbol;
      }
      let dataLocal = null;
      if (crudInfo.Detail.Criteria > '') {
        const json = await window.appContext.transmitter.doCRUD(crudInfo);
        dataLocal = json.Response.data;
      }
      let currentTimeHolder = GetCurrentTime();
      setCurrentTime(currentTimeHolder);
      ResyncRight(dataRight);
      if (window.messageInParsed != null) {
        if (window.messageInParsed.Symbols != null && window.messageInParsed.Symbols.length > 0) {
          setSymbols(window.messageInParsed.Symbols);
        }
        if (window.messageInParsed.SecNum != null) {
          let lastUpdateTimeHolder = GetLastUpdateTime(window.messageInParsed);
          setLastUpdateTime(lastUpdateTimeHolder);
          let delayHolder = GetDelay(lastUpdateTimeHolder, currentTimeHolder);
          setDelay(delayHolder);
          UpdateRight(dataRight, window.messageInParsed);
          setDataRight(dataRight);
        }
      }
      return dataLocal;
    });
    updateData();

    let timer1 = setTimeout(() => setTimer((prevtime) => prevtime + 50), 50);
    return () => {
      clearTimeout(timer1);
    };
  }, [addSymbol, currentTime, dataRight, removeSymbol, submitSymbolGo, restart, watchSymbol, lastUpdateTime, timer]);
  return (
    <Box>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 160, marginLeft: 1, marginTop: 5 }}>
          <InputLabel id="watch-symbol-label">Watch Symbol</InputLabel>
          <Select
            labelId="watch-symbol-label"
            id="watch-symbol"
            value={watchSymbol}
            onChange={handleWatchSymbol}
            label="Watch Symbol"
          >
            <MenuItem value="">
              {symbols.length === 0 ? <em>Click Submit Symbols</em> : <em>None</em>}
            </MenuItem>
            { symbols.map(symbolCur => <MenuItem value={symbolCur} >{symbolCur}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 160, marginLeft: 4, marginTop: 5 }}>
          <InputLabel id="remove-symbol-label">Remove Symbol</InputLabel>
          <Select
            labelId="remove-symbol-label"
            id="remove-symbol"
            value={removeSymbol}
            onChange={handleRemoveSymbol}
            label="Remove Symbol"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            { symbols.map(symbolCur => <MenuItem value={symbolCur} >{symbolCur}</MenuItem>)}
          </Select>
        </FormControl>
        <TextField 
          id="add-symbol" 
          label="Add Symbol" 
          onChange={handleAddSymbol}
          variant="standard" 
          sx={{ m: 1, minWidth: 80, marginLeft: 4, marginTop: 5 }}
        >
        </TextField>
        <Button 
          variant="contained"
          onClick={handleSubmitSymbolGo}
          sx={{ m: 1, minWidth: 120, marginLeft: 3, marginTop: 6 }}
        >Submit Symbol
        </Button>
        <Button 
          variant="contained"
          onClick={handleRestart}
          sx={{ m: 1, minWidth: 120, marginLeft: 6, marginTop: 6 }}
        >Restart Server
        </Button>
        <Typography
          display="inline"
          variant="h6"
          sx={{ m: 1, minWidth: 120, marginLeft: 3, marginTop: 10 }}
        >
          Cur Time: {currentTime}
        </Typography>
        <Typography
          display="inline"
          variant="h6"
          sx={{ m: 1, minWidth: 120, marginLeft: 3, marginTop: 10 }}
        >
          Last Upd: {lastUpdateTime}
        </Typography>
        <Typography
          display="inline"
          variant="h6"
          sx={{ m: 1, minWidth: 120, marginLeft: 3, marginTop: 10 }}
        >
          Delay (msec): {delay}
        </Typography>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <LineChart
              width={2400}
              height={1200}
              data={dataRight}
              margin={{
                top: 5,
                right: 10,
                left: 30,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <XAxis dataKey={"Second"} interval="equidistantPreserveStart" />
              <YAxis yAxisId="right" orientation="right" type="number" domain={['auto', 'auto']} />
              <YAxis yAxisId="left" type="number" domain={['auto', 'auto']} tickFormatter={tick => {return tick.toLocaleString();}} />
              <Tooltip />
              <Legend />
              <Line connectNulls type="linear" yAxisId="right" dataKey="PriceLast" stroke="#00ff00" strokeWidth={2} dot={false} isAnimationActive={false} />
              <Line type="linear" yAxisId="left" dataKey="AggVol" stroke="#0000ff" strokeWidth={2} dot={false} isAnimationActive={false} />
            </LineChart>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
        
export default ContentItemElemGraph2;
