import React from 'react';
import { Box } from '@mui/material'
import ContentList from './ContentList.js';
import ContentItem from './ContentItem.js';

const Content = (props) => {
    console.log("Content - props: ", props);
    return (
        <Box>
            {props != null && props.Shape === 'List' ?
                <ContentList
                    DataAnchor={props.DataAnchor}
                    Columns={props.Columns}
                    ParentId={props.ParentId}
                    CrumbStackSegs={props.CrumbStackSegs}
                    SetStackSegs={props.SetStackSegs}
                    DrilldownMenuItem={props.DrilldownMenuItem}
                />
            :
                props != null ?
                    <ContentItem
                        DataAnchor={props.DataAnchor}
                        Columns={props.Columns}
                        Row={props.Row}
                        CrumbStackSegs={props.CrumbStackSegs}
                        SetStackSegs={props.SetStackSegs}
                        List={props.List}
                    />
                :
                    null
            }
        </Box>
    );
}
        
//export default React.memo(Content);
export default Content;
