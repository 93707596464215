import { Box } from '@mui/material';
import Button from '@mui/material/Button';

const ContentItemElemChild = (props) => {
    console.log("ContentItemElemChild - props: ", props);
    
    const menuItemDrilldown = props.Format.DrilldownMenuItem != null && 
		window.appContext.MenuItems.find(cur => cur.Id === props.Format.DrilldownMenuItem) != null ?
        window.appContext.MenuItems.find(cur => cur.Id === props.Format.DrilldownMenuItem) : props.Format;
   
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: 'flex'
            }}
        >
            <Box
                sx={{
                    marginTop: '8px',
                    display: 'flex',
                    minWidth: '20%'
                }}
                pr={1}
            >
                {props.Format.Label}:
            </Box>
            <Box
                sx={{
                    width: '80%'
                }}
            >
                <Box>
                    <Button
                        sx={{
                            left: '0%',
                            padding: '.8em',
                            paddingTop: '.2em',
                            paddingBottom: '.2em',
                            margin: '1em',
                            minWidth: '30%'
                        }}
                        variant="outlined" color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            let idParentCur = props.DataAnchor.Row.getValue('Id') ?? 
                                props.DataAnchor.Row.original['id'] ?? 
                                props.DataAnchor.Row.original[props.DataAnchor.Columns.find(cur => cur.header === 'Id').accessorKey];
                            let itemPushed = {...menuItemDrilldown};
                            itemPushed.ParentId = idParentCur;
                            props.SetStackSegs(prevStackSegs => {
                                return [...prevStackSegs, itemPushed]
                            });
                        }}
                    >
                        {props.Format.Label}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
        
export default ContentItemElemChild;
