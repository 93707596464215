import { useState } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import ContentItemElem from './ContentItemElem.js';
import AppContext from '../AppContext.mjs';

const ContentItem = (props) => {
    //console.log("ContentItem - props: ", props);
    const [itemData, setItemData] = useState(
        { ...(props.Row != null ? props.Row.original : null) }
    );
    const handleSave = async (props, itemDataToSave, list, parentId, row) => {
        let itemDataToSaveCopy = {...itemDataToSave};
        for (let attrCur in itemDataToSave) {
            let attrDetail = itemDataToSave[attrCur];
            let colFound = props.Columns.find(colCur => colCur.Format.Attr === attrCur);
            if (colFound != null  && colFound.Format.Mask != null) {
                if (colFound.Format.Mask === 'File') {
                    attrDetail.forEach(formDataCur => {
                        for (let entry of formDataCur.entries()) {
                            //console.log(entry[0], entry[1], entry[1]['name'], entry[1]['size'], entry[1]['type']);
                            itemDataToSaveCopy['a__file_name'] = entry[1]['name'];
                            itemDataToSaveCopy['a__file_length'] = entry[1]['size'];
                            itemDataToSaveCopy['a__mime_type'] = entry[1]['type'];
                        }
                        const url = AppContext.BaseUrl+':'+AppContext.UploadPort +'/';
                        fetch(url, {
                            method: 'POST',
                            body: formDataCur
                        }).then(response => {
                            return response.text();
                        }).then(data => {
                            //console.log(data);
                        });
                    });
                }
            }
        }

        let crudInfo = {
            Action: row != null ? 'UpdateRow' : 'InsertRow',
            List: list,
            ParentId: parentId,
            Data: itemDataToSaveCopy
        };
        const json = await window.appContext.transmitter.doCRUD(crudInfo);
        let dataLocal = json.Response.data;
        props.SetStackSegs(prevStackSegs => {
            let stackSegsNew = [...prevStackSegs];
            stackSegsNew.pop();
            return [...stackSegsNew]
        });
    };
    const handleCancel = async () => {
        props.SetStackSegs(prevStackSegs => {
            let stackSegsNew = [...prevStackSegs];
            stackSegsNew.pop();
            return [...stackSegsNew]
        });
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    return (
        <Box>
            <form>
                {props.Columns.map((colCur, colIndex) =>
                    <ContentItemElem
                        Format={colCur.Format}
                        Data={props.Row != null ? itemData[colCur.Format.Attr] : null}
                        DataAnchor={props.DataAnchor}
                        CrumbStackSegs={props.CrumbStackSegs}
                        SetStackSegs={props.SetStackSegs}
                        ItemData={itemData}
                        SetItemData={setItemData}
                    >
                    </ContentItemElem>
                )}
                <Button
                    sx={{
                        left: '20%',
                        padding: '.8em',
                        paddingTop: '.2em',
                        paddingBottom: '.2em',
                        margin: '1em'
                    }}
                    variant="outlined" color="success"
                    onClick={e => {
                        e.stopPropagation();
                        handleSave(props, itemData, props.List, props.DataAnchor.ParentId ?? '', props.Row ?? null);
                    }}
                >
                    Save
                </Button>
                <Button
                    sx={{
                        left: '20%',
                        padding: '.8em',
                        paddingTop: '.2em',
                        paddingBottom: '.2em',
                        margin: '1em'
                    }}
                    variant="outlined" color="error"
                    onClick={e => {
                        e.stopPropagation();
                        handleCancel();
                    }}
                >
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default ContentItem;
