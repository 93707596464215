import { Box } from '@mui/material';
import ContentItemElemPrimitve from './ContentItemElemPrimitive.js';
import ContentItemElemEmbedded from './ContentItemElemEmbedded.js';
import ContentItemElemChild from './ContentItemElemChild.js';
import ContentItemElemGraph from './ContentItemElemGraph.js';

const ContentItemElem = (props) => {
    //console.log("ContentItemElem - props: ", props);
    return (
        <Box>
            {props.Format.ElemType === 'Primitive' ?
                <ContentItemElemPrimitve
                    Format={props.Format}
                    Data={props.Data}
                    DataAnchor={props.DataAnchor}
                    CrumbStackSegs={props.CrumbStackSegs}
                    SetStackSegs={props.SetStackSegs}
                    ItemData={props.ItemData}
                    SetItemData={props.SetItemData}
                >
                </ContentItemElemPrimitve>
            :
            props.Format.ElemType === 'Embedded' ?
                <ContentItemElemEmbedded
                    Format={props.Format}
                    Data={props.Data}
                    DataAnchor={props.DataAnchor}
                    CrumbStackSegs={props.CrumbStackSegs}
                    SetStackSegs={props.SetStackSegs}
                    ItemData={props.ItemData}
                    SetItemData={props.SetItemData}
                >
                </ContentItemElemEmbedded>
            :
            props.Format.ElemType === 'Child' ?
                <ContentItemElemChild
                    Format={props.Format}
                    Data={props.Data}
                    DataAnchor={props.DataAnchor}
                    CrumbStackSegs={props.CrumbStackSegs}
                    SetStackSegs={props.SetStackSegs}
                    ItemData={props.ItemData}
                    SetItemData={props.SetItemData}
                >
                </ContentItemElemChild>
            :
            props.Format.ElemType === 'Graph' ?
                <ContentItemElemGraph
                Format={props.Format}
                Data={props.Data}
                DataAnchor={props.DataAnchor}
                CrumbStackSegs={props.CrumbStackSegs}
                SetStackSegs={props.SetStackSegs}
                ItemData={props.ItemData}
                SetItemData={props.SetItemData}
                >
                </ContentItemElemGraph>
            :
            ''
            }
        </Box>
    );
}
        
export default ContentItemElem;
