import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logo from '../image/compass-rose-on-old-paper.jpg';
import jwt_decode from "jwt-decode";

const checkUserAuthentication = (signInOrUp) => {
    if (window.appContext.transmitter.websocketBEIsActive === true) {
        if (signInOrUp.IsAuthenticated === false) {
            if (document.location.hash != null) {
                let cognitoData = {}; 
                let elementsString = decodeURIComponent(document.location.hash.substr(1, document.location.hash.length));
                let params = elementsString.split("&");
                for (let param of params) {
                    let values = param.split("=");
                    cognitoData[values[0]] = values[1];
                }
                if (cognitoData["id_token"] != null) {
                    let idDecoded = jwt_decode(cognitoData["id_token"], {header: false});
                    //console.log("idDecoded:",idDecoded);
                    signInOrUp.UserEmail = idDecoded.email.toLowerCase();
                    signInOrUp.IsAuthenticated = true;
                }
            }
        }
    } else {
        setTimeout(() => { checkUserAuthentication(signInOrUp); }, 10);
    }
}

const Masthead = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const loginMenuOpen = Boolean(anchorEl);
    const handleLoginMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLoginClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        handleLoginClose();
        window.signInOrUp.IsAuthenticated = false;
        window.signInOrUp.UserEmail = '';
        window.location = props.LogoutURI;
    };
    React.useEffect(() => {
        checkUserAuthentication(props.SignInOrUp);
    });  

    return (
        <div style={{width: "100%"}}> 
            {props.SignInOrUp.UserEmail == null || props.SignInOrUp.UserEmail === '' ?
            <div style={{width: "100%"}}>
                <Button color="inherit" href={props.RedirectURI}
                    style={{ fontSize: '20px', textAlign: 'center', border: 'solid', borderWidth: '3px', paddingLeft: '2em', paddingRight: '2em' }} >
                    Sign In
                </Button>
            </div>
            :
            <div style={{width: "100%"}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',  width: '30%', maxWidth: '30%', padding: '2em', flexGrow: 1 }}>
                        <Typography variant="h6">
                            {'Application'}
                        </Typography>
                        <Typography variant="subtitle2" display="block" >
                            {'Data'}
                        </Typography>
                        <Typography variant="subtitle2" display="block" >
                            {'Testing'}
                        </Typography>
                    </div>
                    <img src={Logo} style={{ height: '120px', width: '154px', maxWidth: '154px', flexGrow: 1 }} alt='' />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '30%', maxWidth: '30%', padding: '1em', fontSize: '36px', flexGrow: 1 }}>
                    <Button
                            onClick={handleLoginMenu}
                        >
                            <AccountCircle />
                            &nbsp;&nbsp;&nbsp;
                                <Typography variant="subtitle1" noWrap>
                                    {props.SignInOrUp.UserEmail}
                                </Typography>
                        </Button>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={loginMenuOpen}
                            onClose={handleLoginClose}
                        >
                            {false ?
                                <MenuItem onClick={handleLoginClose}>{'props.ViewSignInOrUp.model.Attrs.Name.Value'}</MenuItem>
                                :
                                ''
                            }
                            <MenuItem onClick={handleLogout} >Sign off</MenuItem>
                        </Menu>

                    </div>
                </div>
            </div>
        } </div>
    );
}
    
export default Masthead;


//{false /*window.user == null || window.user.data == null || window.user.data.user_login == null*/ ?
