const AppContext_CNR = {
    AppId: 'CNR_N',
    BaseUrl: 'https://plainvanillainfo.net', 
    WebsocketUrl: 'wss://plainvanillainfo.net:8607',
    UploadPort: '8807',
    DownloadPort: '8707',
    AuthUrl: 'https://desertstreaming.auth.us-east-2.amazoncognito.com',
    AuthClient: '5s8bdf30m9af26t5j7a5jfv7q6',
    IsAuthenticated: false,
    UserEmail: '',
    MenuItems: [
        {Id:'Insiders', Label: 'Insiders', Shape: 'List',
            Columns: [
                {   accessorKey: 'i__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'i__insider_id_type',
                    header: 'Insider Id Type',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__insider_id_type',
                        Label: 'Insider Id Typee',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'i__insider_id',
                    header: 'Insider Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__insider_id',
                        Label: 'Insider Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'i__insider_name',
                    header: 'Insider Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__insider_name',
                        Label: 'Insider Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'InsiderItem', IsTopLevel: true
        },
        {Id:'InsiderItem', Label: 'Insider', Shape: 'Form',
            Columns: [
                {   accessorKey: 'i__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'i__insider_id_type',
                    header: 'Insider Id Type',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__insider_id_type',
                        Label: 'Insider Id Typee',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'i__insider_id',
                    header: 'Insider Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__insider_id',
                        Label: 'Insider Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'i__insider_name',
                    header: 'Insider Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'i__insider_name',
                        Label: 'Insider Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {Id:'Filings', Label: 'Filings', Shape: 'List',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'FilingItem', IsTopLevel: false
        },
        {Id:'FilingItem', Label: 'Filing', Shape: 'Form',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'semantic__content_json',
                    header: 'Filings',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'semantic__content_json',
                        Label: 'Filings',
                        Mask: 'Json'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], IsTopLevel: false
        }
    ]
};

const AppContext_PRC = {
    AppId: 'PRC_N',
    BaseUrl: 'https://desertstreaming.com', 
    WebsocketUrl: 'wss://desertstreaming.com:8603',
    UploadPort: '8803',
    DownloadPort: '8703',
    AuthUrl: 'https://desertstreaming.auth.us-east-2.amazoncognito.com',
    AuthClient: '5s8bdf30m9af26t5j7a5jfv7q6',
    IsAuthenticated: false,
    UserEmail: '',
    MenuItems: [
        {Id:'TradesInPeriod', Label: 'Trades In Period', Shape: 'List',
            Columns: [
                {   accessorKey: 'date_string',
                    header: 'Date',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'date_string',
                        Label: 'Date',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'time_string',
                    header: 'Time',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'time_string',
                        Label: 'Time',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'ticker',
                    header: 'Ticker',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ticker',
                        Label: 'ticker',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: true
        },
        {Id:'Securities', Label: 'Securities', Shape: 'List',
            Columns: [
                {   accessorKey: 'ticker',
                    header: 'Ticker',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ticker',
                        Label: 'Ticker',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'security_type',
                    header: 'Security Type',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'security_type',
                        Label: 'Security Type',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'SecurityItem', IsTopLevel: true
        },
        {Id:'SecurityItem', Label: 'Security', Shape: 'Form',
            Columns: [
                {   accessorKey: 'ticker',
                    header: 'Ticker',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ticker',
                        Label: 'Ticker',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'security_type',
                    header: 'Security Type',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'security_type',
                        Label: 'Security Type',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        }
    ]
}

const AppContext_OPT = {
    AppId: 'OPT_N',
    BaseUrl: 'http://plainvanillainfo.net', 
    WebsocketUrl: 'ws://plainvanillainfo.net:8607',
    UploadPort: '8807',
    DownloadPort: '8707',
    AuthUrl: 'https://desertstreaming.auth.us-east-2.amazoncognito.com',
    AuthClient: '5s8bdf30m9af26t5j7a5jfv7q6',
    IsAuthenticated: false,
    UserEmail: '',
    MenuItems: [
        {Id:'DashboardItem', Label: 'Dashboard', Shape: 'Form',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {Id:'Processes', Label: 'Processes', Shape: 'List',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'ProcessItem', IsTopLevel: false
        },
        {Id:'ProcessItem', Label: 'Process', Shape: 'Form',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {Id:'Websites', Label: 'Websites', Shape: 'List',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'WebsiteItem', IsTopLevel: false
        },
        {Id:'WebsiteItem', Label: 'Website', Shape: 'Form',
            Columns: [
                {   accessorKey: 'id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'id',
                        Label: 'Id',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'name',
                    header: 'Name',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 0, Data: null, CrumbStackSegs: [], IsTopLevel: false
        }
    ]
}

const AppContext_CPL = {
    AppId: 'CPL_N',
    BaseUrl: 'https://condopartyline.net', 
    WebsocketUrl: 'wss://condopartyline.net:8604',
    UploadPort: '8804',
    DownloadPort: '8704',
    AuthUrl: 'https://crma-team.auth.us-east-2.amazoncognito.com/login?response_type=token&client_id=2d3ilj482i2ejtrm35nqml1733&redirect_uri=https://condopartyline.net/',
    DeAuthUrl: 'https://crma-team.auth.us-east-2.amazoncognito.com/logout?client_id=2d3ilj482i2ejtrm35nqml1733&logout_uri=https://condopartyline.net/logout',
    //AuthUrl: 'https://crma-team.auth.us-east-2.amazoncognito.com',
    //AuthClient: 'geaabkgua3kkq2niabh9ucuse',
    IsAuthenticated: false,
    UserEmail: '',
    MenuItems: [
        {   Id: 'CommunitiesActive', Label: 'Communities', Shape: 'List',
            Columns: [
                {   accessorKey: 'c__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'c__abbreviation',
                    header: 'Abbreviation',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__abbreviation',
                        Label: 'Abbreviation',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__community_name',
                    header: 'Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__community_name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__community_status',
                    header: 'Status',
                    filterVariant: 'select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__community_status',
                        Label: 'Status',
                        Mask: 'Radio',
                        MaskValueSet: ['Active', 'Inactive']
                    }
                },
                {   accessorKey: 'c__community_location',
                    header: 'Location',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__community_location',
                        Label: 'Location',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__dos_document_no',
                    header: 'DOS Doc No',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__dos_document_no',
                        Label: 'DOS Doc No',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__ein',
                    header: 'EIN',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__ein',
                        Label: 'EIN',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__date_incorporated',
                    header: 'Date Incorp',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__date_incorporated',
                        Label: 'Date Incorp',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'Community', IsTopLevel: true
        },

        {   Id: 'GlobalTasks', Label: 'Tasks', Shape: 'List',
            Columns: [
                {   accessorKey: 't__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 't__task_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_description',
                        Label: 'Description',
                        Mask: 'Textarea'
                    }
                },
                {   accessorKey: 't__task_begin',
                    header: 'Begin',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_begin',
                        Label: 'Begin',
                        Mask: 'DateTime'
                    }
                },
                {   accessorKey: 't__task_initiator',
                    header: 'Initiator',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_initiator',
                        Label: 'Initiator',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 't__assignee',
                    header: 'Assignee',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__assignee',
                        Label: 'Assignee',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 't__task_end',
                    header: 'End',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_end',
                        Label: 'End',
                        Mask: 'DateTime'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'CommunityElementTask', IsTopLevel: true
        },

        {   Id: 'Community', Label: 'Community', Shape: 'Form',
            Columns: [
                {   accessorKey: 'c__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'c__community_name',
                    header: 'Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__community_name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__abbreviation',
                    header: 'Abbreviation',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__abbreviation',
                        Label: 'Abbreviation',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__community_location',
                    header: 'Location',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__community_location',
                        Label: 'Location',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__dos_document_no',
                    header: 'DOS Doc No',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__dos_document_no',
                        Label: 'DOS Doc No',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__ein',
                    header: 'EIN',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__ein',
                        Label: 'EIN',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__date_incorporated',
                    header: 'Date Incorp',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__date_incorporated',
                        Label: 'Date Incorp',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'c__community_status',
                    header: 'Status',
                    filterVariant: 'multi-select',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'c__community_status',
                        Label: 'Status',
                        Mask: 'Radio',
                        MaskValueSet: ['Active', 'Inactive']
                    }
                },
                {   accessorKey: 'CommunityElementsPropertyUnits',
                    header: 'Property Units',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'CommunityElementsPropertyUnits',
                        Label: 'Property Units',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'CommunityElementsPropertyUnits'
                    }
                },
                {   accessorKey: 'CommunityInsurances',
                    header: 'Insurance Policies',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'CommunityInsurances',
                        Label: 'Insurance Policies',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'CommunityInsurances'
                    }
                },
                {   accessorKey: 'CommunityContracts',
                    header: 'Contracts',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'CommunityContracts',
                        Label: 'Contracts',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'CommunityContracts'
                    }
                },
                {   accessorKey: 'CommunityLegalDocs',
                    header: 'Legal Documents',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'CommunityLegalDocs',
                        Label: 'Legal Documents',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'CommunityLegalDocs'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CommunityElementsPropertyUnits', Label: 'Property Units', Shape: 'List',
            Columns: [
                {   accessorKey: 'pu__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'pu__property_unit_name',
                    header: 'Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__property_unit_name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__street_1',
                    header: 'Street 1',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__street_1',
                        Label: 'Street 1',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__street_2',
                    header: 'Street 2',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__street_2',
                        Label: 'Street 2',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__street_3',
                    header: 'Street 3',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__street_3',
                        Label: 'Street 3',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__city',
                    header: 'City',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__city',
                        Label: 'City',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__state_province',
                    header: 'State/Province',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__state_province',
                        Label: 'State/Province',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__zip_postal_code',
                    header: 'Zip/Postal Code',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__zip_postal_code',
                        Label: 'Zip/Postal Code',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__country',
                    header: 'Country',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__country',
                        Label: 'City',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__email_address',
                    header: 'Email',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__email_address',
                        Label: 'Email',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__phone_number',
                    header: 'Phone',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__phone_number',
                        Label: 'Phone',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__alt_phone_number',
                    header: 'Alt Phone',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__alt_phone_number',
                        Label: 'Alt Phone',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__owner_name',
                    header: 'Owner Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__owner_name',
                        Label: 'Owner Name',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__street_1',
                    header: 'Owner Street 1',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__street_1',
                        Label: 'Owner Street 1',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__street_2',
                    header: 'Owner Street 2',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__street_2',
                        Label: 'Owner Street 2',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__street_3',
                    header: 'Owner Street 3',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__street_3',
                        Label: 'Owner Street 3',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__city',
                    header: 'Owner City',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__city',
                        Label: 'Owner City',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__state_province',
                    header: 'Owner State/Province',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__state_province',
                        Label: 'Owner State/Province',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__zip_postal_code',
                    header: 'Owner Zip/Postal Code',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__zip_postal_code',
                        Label: 'Owner Zip/Postal Code',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__country',
                    header: 'Owner Country',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__country',
                        Label: 'Owner Country',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'PropertyUnit', IsTopLevel: false
        },
        {   Id: 'PropertyUnit', Label: 'Property Unit', Shape: 'Form',
            Columns: [
                {   accessorKey: 'pu__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'pu__property_unit_name',
                    header: 'Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__property_unit_name',
                        Label: 'Name',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'Tasks',
                    header: 'Tasks',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'Tasks',
                        Label: 'Tasks',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'CommunityElementTasks'
                    }
                },
                {   accessorKey: 'pu__street_1',
                    header: 'Street 1',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__street_1',
                        Label: 'Street 1',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__street_2',
                    header: 'Street 2',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__street_2',
                        Label: 'Street 2',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__street_3',
                    header: 'Street 3',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__street_3',
                        Label: 'Street 3',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__city',
                    header: 'City',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__city',
                        Label: 'City',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__state_province',
                    header: 'State/Province',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__state_province',
                        Label: 'State/Province',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__zip_postal_code',
                    header: 'Zip/Postal Code',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__zip_postal_code',
                        Label: 'Zip/Postal Code',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__country',
                    header: 'Country',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__country',
                        Label: 'City',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'pu__current_owner',
                    header: 'Current Owner',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'pu__current_owner',
                        Label: 'Current Owner',
                        Mask: 'Text',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'puo__email_address',
                    header: 'Email',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__email_address',
                        Label: 'Email',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__phone_number',
                    header: 'Phone',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__phone_number',
                        Label: 'Phone',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__alt_phone_number',
                    header: 'Alt Phone',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__alt_phone_number',
                        Label: 'Alt Phone',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__owner_name',
                    header: 'Owner Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__owner_name',
                        Label: 'Owner Name',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__street_1',
                    header: 'Owner Street 1',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__street_1',
                        Label: 'Owner Street 1',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__street_2',
                    header: 'Owner Street 2',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__street_2',
                        Label: 'Owner Street 2',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__street_3',
                    header: 'Owner Street 3',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__street_3',
                        Label: 'Owner Street 3',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__city',
                    header: 'Owner City',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__city',
                        Label: 'Owner City',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__state_province',
                    header: 'Owner State/Province',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__state_province',
                        Label: 'Owner State/Province',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__zip_postal_code',
                    header: 'Owner Zip/Postal Code',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__zip_postal_code',
                        Label: 'Owner Zip/Postal Code',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'puo__country',
                    header: 'Owner Country',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'puo__country',
                        Label: 'Owner Country',
                        Mask: 'Text'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CommunityElementsCommonElements', Label: 'Common Elements', Shape: 'List',
            Columns: [],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CommunityElementTasks', Label: 'Tasks', Shape: 'List',
            Columns: [
                {   accessorKey: 't__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 't__task_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_description',
                        Label: 'Description',
                        Mask: 'Textarea'
                    }
                },
                {   accessorKey: 't__task_begin',
                    header: 'Begin',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_begin',
                        Label: 'Begin',
                        Mask: 'DateTime'
                    }
                },
                {   accessorKey: 't__task_initiator',
                    header: 'Initiator',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_initiator',
                        Label: 'Initiator',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 't__assignee',
                    header: 'Assignee',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__assignee',
                        Label: 'Assignee',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 't__task_end',
                    header: 'End',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_end',
                        Label: 'End',
                        Mask: 'DateTime'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'CommunityElementTask', IsTopLevel: false
        },
        {   Id: 'CommunityElementTask', Label: 'Task', Shape: 'Form',
            Columns: [
                {   accessorKey: 't__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 't__task_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_description',
                        Label: 'Description',
                        Mask: 'Textarea'
                    }
                },
                {   accessorKey: 't__task_begin',
                    header: 'Begin',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_begin',
                        Label: 'Begin',
                        Mask: 'DateTime'
                    }
                },
                {   accessorKey: 't__task_initiator',
                    header: 'Initiator',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_initiator',
                        Label: 'Initiator',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 't__assignee',
                    header: 'Assignee',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__assignee',
                        Label: 'Assignee',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 't__task_end',
                    header: 'End',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 't__task_end',
                        Label: 'End',
                        Mask: 'DateTime'
                    }
                },
                {   accessorKey: 'TaskAttachments',
                    header: 'TaskAttachments',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'TaskAttachments',
                        Label: 'Task Attachments',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'TaskAttachments'
                    }
                },
                {   accessorKey: 'TaskTaskActions',
                    header: 'TaskTaskActions',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'TaskTaskActions',
                        Label: 'Task Actions',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'TaskTaskActions'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'TaskAttachments', Label: 'Task Attachments', Shape: 'List',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'TaskAttachment', IsTopLevel: false
        },
        {   Id: 'TaskAttachment', Label: 'Task Attachment', Shape: 'Form',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'TaskTaskActions', Label: 'Task Actions', Shape: 'List',
            Columns: [
                {   accessorKey: 'ta__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ta__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'ta__action_time',
                    header: 'TimeStamp',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ta__action_time',
                        Label: 'TimeStamp',
                        Mask: 'DateTime'
                    }
                },
                {   accessorKey: 'ta__action_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ta__action_description',
                        Label: 'Description',
                        Mask: 'Textarea'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'TaskTaskAction', IsTopLevel: false
        },
        {   Id: 'TaskTaskAction', Label: 'Task Action', Shape: 'Form',
            Columns: [
                {   accessorKey: 'ta__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ta__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'ta__action_time',
                    header: 'TimeStamp',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ta__action_time',
                        Label: 'TimeStamp',
                        Mask: 'DateTime'
                    }
                },
                {   accessorKey: 'ta__action_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'ta__action_description',
                        Label: 'Description',
                        Mask: 'Textarea'
                    }
                },
                {   accessorKey: 'TaskActionAttachments',
                    header: 'TaskActionAttachments',
                    Format: {
                        ElemType: 'Child',
                        Attr: 'TaskActionAttachments',
                        Label: 'Task Action Attachments',
                        Mask: 'Drilldown',
                        DrilldownMenuItem: 'TaskActionAttachments'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'TaskActionAttachments', Label: 'Task Action Attachments', Shape: 'List',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'TaskActionAttachment', IsTopLevel: false
        },
        {   Id: 'TaskActionAttachment', Label: 'Task Action Attachment', Shape: 'Form',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CommunityInsurances', Label: 'Insurance Policies', Shape: 'List',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'CommunityInsurance', IsTopLevel: false
        },
        {   Id: 'CommunityInsurance', Label: 'Insurance Policy', Shape: 'Form',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CommunityContracts', Label: 'Community Contracts', Shape: 'List',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'CommunityContract', IsTopLevel: false
        },
        {   Id: 'CommunityContract', Label: 'Community Contract', Shape: 'Form',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CommunityLegalDocs', Label: 'Community Legal Docs', Shape: 'List',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], DrilldownMenuItem: 'CommunityLegalDoc', IsTopLevel: false
        },
        {   Id: 'CommunityLegalDoc', Label: 'Community Legal Doc', Shape: 'Form',
            Columns: [
                {   accessorKey: 'a__id',
                    header: 'Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__id',
                        Label: 'Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__file_description',
                    header: 'Description',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_description',
                        Label: 'Description',
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_name',
                    header: 'File Name',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_name',
                        Label: 'File Name',
                        Mask: 'File'
                    }
                },
                {   accessorKey: 'a__mime_type',
                    header: 'Mime Type',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__mime_type',
                        Label: 'Mime Type',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__file_length',
                    header: 'Length',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__file_length',
                        Label: 'Length',
                        Disabled: true,
                        Mask: 'Text'
                    }
                },
                {   accessorKey: 'a__storage_id',
                    header: 'Storage Id',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_id',
                        Label: 'Storage Id',
                        Visibility: 'Hidden'
                    }
                },
                {   accessorKey: 'a__storage_path',
                    header: 'Storage Path',
                    Format: {
                        ElemType: 'Primitive',
                        Attr: 'a__storage_path',
                        Label: 'Storage Path',
                        Visibility: 'Hidden'
                    }
                }
            ],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CalendarUpcomingEvents', Label: 'Upcoming Events', Shape: 'List',
            Columns: [],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        },
        {   Id: 'CalendarUpcomingAll', Label: 'Upcoming All', Shape: 'List',
            Columns: [],
            KeyOffset: 1, Data: null, CrumbStackSegs: [], IsTopLevel: false
        }
    ]
};

const AppContext_HFP = {
    AppId: 'HFP_N',
    BaseUrl: 'https://plainliterecy.org', 
    WebsocketUrl: 'wss://plainliterecy.com:8606',
    UploadPort: '8806',
    DownloadPort: '8706',
    AuthUrl: 'https://desertstreaming.auth.us-east-2.amazoncognito.com',
    AuthClient: '5s8bdf30m9af26t5j7a5jfv7q6',
    IsAuthenticated: false,
    UserEmail: '',
    MenuItems: [
    ]
}

let AppContext = AppContext_CPL;

export default AppContext;
