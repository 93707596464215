class Transmitter {
  constructor() {
    this.websocketBE = null;
    this.websocketBEIsActive = false;
    this.sessionId = null;
    this.pollInterval = 20;
    this.fetchesUnderway = {};
    this.crudIdNext = 1;
  }

  startSessionServer(parent, url) {
    this.parent = parent;
    this.websocketBE = new WebSocket(url);
    setTimeout(() => { this.keepAlive(); }, 30000);
    this.websocketBE.onopen = () => {
      this.websocketBEIsActive = true;
      console.log("Websocket opened");
    };
    this.websocketBE.onclose = (e) => {
      this.websocketBEIsActive = false;
      var reason = 'Unknown error';
      switch (e.code) {
        case 1000:
          reason = 'Normal closure';
          break;
        case 1001:
          reason = 'An endpoint is going away';
          break;
        case 1002:
          reason = 'An endpoint is terminating the connection due to a protocol error.';
          break;
        case 1003:
          reason = 'An endpoint is terminating the connection because it has received a type of data it cannot accept';
          break;
        case 1004:
          reason = 'Reserved. The specific meaning might be defined in the future.';
          break;
        case 1005:
          reason = 'No status code was actually present';
          break;
        case 1006:
          reason = 'The connection was closed abnormally';
          break;
        case 1007:
          reason = 'The endpoint is terminating the connection because a message was received that contained inconsistent data';
          break;
        case 1008:
          reason = 'The endpoint is terminating the connection because it received a message that violates its policy';
          break;
        case 1009:
          reason = 'The endpoint is terminating the connection because a data frame was received that is too large';
          break;
        case 1010:
          reason = 'The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn\'t.';
          break;
        case 1011:
          reason = 'The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.';
          break;
        case 1012:
          reason = 'The server is terminating the connection because it is restarting';
          break;
        case 1013:
          reason = 'The server is terminating the connection due to a temporary condition';
          break;
        case 1015:
          reason = 'The connection was closed due to a failure to perform a TLS handshake';
          break;
        default:
          break;
      }

      // Show 'closed' message on screen
      console.log("Websocket close error: " + reason);
    };
    this.websocketBE.onmessage = (evt) => {
      var messageInParsed = JSON.parse(evt.data);
      if (messageInParsed.Id != null) {
        //console.log("messageInParsed.Id != null");
        this.fetchesUnderway[messageInParsed.Id.toString()].Response = messageInParsed;
      } else {
        window.messageInParsed = messageInParsed;
      }
      if (window.sessionId == null && messageInParsed.SessionId != null) {
        window.sessionId = messageInParsed.SessionId;
        let x = 3;
      }
    };
    this.websocketBE.onerror = (ev) => {
      console.log("Websocket open error: " + ev.data, ev);
    }
  }

  keepAlive() {
    this.sendMessageToBE('{}');
    setTimeout(() => { this.keepAlive(); }, 30000);
  }

  sendMessageToBE(message) {
    if (this.websocketBEIsActive === true) {
      this.websocketBE.send(message);
    }
  }

  doCRUD(criteria) {
    //console.log("doCRUD - a - criteria:", criteria);
    return new Promise((resolve, reject) => {
      let idNext = this.crudIdNext;
      if (idNext === 100) {
        this.crudIdNext = 1;
      }
      this.fetchesUnderway[idNext.toString()] = {};
      //console.log("doCRUD - criteria:", criteria);
      let message = {
        AppId: this.parent.AppId,
        Id: idNext,
        ...criteria
      };
      this.sendMessageToBE(JSON.stringify(message));
      let intervalId = setInterval(() => {
        if (this.fetchesUnderway[message.Id.toString()].Response != null) {
          let response = this.fetchesUnderway[message.Id.toString()].Response;
          //console.log("doCRUD - response:", response);
          this.fetchesUnderway[message.Id.toString()].Response = null;
          clearInterval(intervalId);
          intervalId = null;
          resolve(response);
        }
      }, this.pollInterval);
    })

  }

}

export default Transmitter;
