import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField';

const ContentItemElemGraphOPT = (props) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [timer, setTimer] = useState(0);
    const [token, setToken] = useState('100000');
    const handleToken = (event) => {
      setToken(event.target.value);
    };
  useEffect(() => {
    const updateData = (async () => {
      let criteria = '';
      let dataLocal = null;
      let crudInfo = {
        Action: 'SetCriteria',
        Detail: {
          Criteria: criteria
        },
        GlobalFilter: ''
      };
      if (crudInfo.Detail.Criteria > '') {
        const json = await window.appContext.transmitter.doCRUD(crudInfo);
        dataLocal = json.Response.data;
      }
      return dataLocal;
    });
    updateData();

    let timer1 = setTimeout(() => setTimer((prevtime) => prevtime + (isInitialized ? 1000 : 1000)), (isInitialized ? 1000 : 1000));
    return () => {
      clearTimeout(timer1);
    };
  }, [token, timer, isInitialized]);
  return (
    <Box>
      <div>
        <TextField 
          id="token" 
          type="password" 
          label="Token" 
          onChange={handleToken}
          variant="standard" 
          sx={{ m: 1, minWidth: 80, marginLeft: 10, marginTop: 5 }} 
        />
      </div>
    </Box>
  );
}
        
export default ContentItemElemGraphOPT;
