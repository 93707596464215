import React, { useState, useEffect, useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Autocomplete,
    createFilterOptions
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const ContentList = (props) => {
    //data and fetching state
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [timer, setTimer] = useState(null);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    
    const columns = props.Columns;
    const columnsDrilldown = props.DrilldownMenuItem != null && 
		window.appContext.MenuItems.find(cur => cur.Id === props.DrilldownMenuItem) != null ?
        window.appContext.MenuItems.find(cur => cur.Id === props.DrilldownMenuItem).Columns : columns;
    let initialState = {
        showColumnFilters: true,
        showGlobalFilter: true,
        pagination: { pageSize: 25, pageIndex: 0 },
        columnVisibility: {}
    };
    columns.forEach(columnCur => {
        if (columnCur.Format.Visibility != null && columnCur.Format.Visibility === 'Hidden') {
            initialState.columnVisibility[columnCur.accessorKey] = false
        }
    });

    // Perform DB query based on suggestion selected by use
    useEffect(() => {
        const fetchData = async () => {
            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }
            let crudInfo = {
                Action: 'FetchRows',
                List: props.DataAnchor.Id ?? '',
                ParentId: props.ParentId ?? '',
                Filters: encodeURIComponent(JSON.stringify(columnFilters ?? [])),
                GlobalFilter: globalFilter ?? ''
            };
            console.log("useEffect - BBB:", JSON.stringify(crudInfo).substring(0, 500));
            const json = await window.appContext.transmitter.doCRUD(crudInfo);

            let dataLocal = json.Response.data;
            setRowCount(dataLocal.length);
            setData(dataLocal);
            //console.log("useEffect: ", JSON.stringify(dataLocal).substring(0, 80000));
            
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        };
        console.log("useEffect - AAA - value:", value);
        if (value != null) {
            fetchData();
        }
    }, [value /*columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting*/]);

    // Make suggestions based on tokens input by user
    useEffect(() => {
        console.log("useEffect");
        const optionPicked = async (optionValue) => {
            console.log("optionPicked", optionValue);
            let crudInfo = {
                Action: 'SetInputTokens',
                List: '',
                ParentId: '',
                Filters: '',
                GlobalFilter: optionValue
            };
            const json = await window.appContext.transmitter.doCRUD(crudInfo);
            let dataLocal = json.Response.data;
            //console.log(dataLocal);
            setRowCount(dataLocal.length);
            setData(dataLocal);
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
        const preparePickableOptions = (newOptions, inputValue) => {
            console.log("preparePickableOptions");
            let retVal = [];
            let tokensTyped = inputValue.split(' ');
            // Are there suggested token sets based on input values?
            if (tokensTyped) {
                // Yes: present them as pickable options
                retVal = [...newOptions, inputValue, ...tokensTyped];
            } else {
                retVal = [...newOptions];
            }
            return retVal;
        };
        const monitorTokenSets = async () => {
            console.log("monitorTokenSets - begin");
            if (active) {
                console.log("monitorTokenSets - is active");
                let newOptions = [];
                // Did user pick a token set?
                if (value) {
                    // Yes: process the picked token set
                    console.log("monitorTokenSets - user picked a token set");
                    newOptions = optionPicked(value);
                    newOptions = [value];
                }
                newOptions = preparePickableOptions(newOptions, inputValue);
                setOptions(newOptions);
            }
            console.log("monitorTokenSets - end");
            return undefined;
        }; 
        let active = true;
        console.log("useEffect - mid ", value, " - ", inputValue);
        if (inputValue === '') {
            console.log("useEffect - inputValue === ''");
            setOptions(value ? [value] : []);
            return undefined;
        }
        clearTimeout(timer);
        let timerTemp = setTimeout(() => monitorTokenSets(), 400);
        setTimer(timerTemp);
        console.log("useEffect - end");
        return () => {
            console.log("useEffect - return");
            active = false;
        };
    }, [value, inputValue]);

    const table = useMaterialReactTable({
        columns, 
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableFacetedValues: true,
        enableGlobalFilter: false,
        showGlobalFilter: false,
        positionGlobalFilter: 'left',
        renderTopToolbarCustomActions: ({ table }) => (
            <Box>
                <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                    <Autocomplete
                        id="navigation-control"
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        options={options}
                        value={value}
                        filterOptions={(x) => x}
                        onChange={(event, newValue) => {
                            setOptions(newValue ? [newValue, ...options] : options);
                            setValue(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}                 
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                        }}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                {option}
                              </li>
                            );
                        }}                        
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Type search terms here ..."
                                sx={{ width: '30em' }}
                                InputProps={{
                                    type: 'search',
                                    ...params.InputProps,
                                    sx: { borderRadius: '20px' },
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Button
                        color="secondary"
                        onClick={
                            (e) => {
                                e.preventDefault();
                                let keyOffset = props.DataAnchor.KeyOffset != null ? props.DataAnchor.KeyOffset : 1;
                                let itemPushed = {
                                    Id: null,
                                    Label: 'New',
                                    KeyOffset: keyOffset,
                                    Data: data,
                                    CrumbStackSegs: [],
                                    Shape: 'Form',
                                    Columns: columnsDrilldown,
                                    Row: null,
                                    List: props.DataAnchor.Id ?? '',
                                    ParentId: props.DataAnchor.ParentId ?? ''
                                };
                                props.SetStackSegs(prevStackSegs => {
                                    return [...prevStackSegs, itemPushed]
                                });
                            }
                        }
                        variant="contained"
                    >
                        Create New
                    </Button>
                </Box>
            </Box>
        ),
        initialState,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (e) => {
                e.preventDefault();
                let keyOffset = props.DataAnchor.KeyOffset != null ? props.DataAnchor.KeyOffset : 1;
                let itemPushed = {
                    Id: row.original[props.Columns[keyOffset].accessorKey], 
                    Label: row.original[props.Columns[keyOffset].accessorKey], 
                    KeyOffset: keyOffset,
                    Data: data, 
                    CrumbStackSegs: [],
                    Shape: 'Form',
                    Columns: columnsDrilldown,
                    Row: row,
                    List: props.DataAnchor.Id ?? ''
                };
                props.SetStackSegs(prevStackSegs => {
                    return [...prevStackSegs, itemPushed]
                });
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),        
    });
    return  <MaterialReactTable 
                table={table}
            />;
};

export default ContentList;
